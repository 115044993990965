* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

#backGround {
  /* background: url(../public/bcgrnd.png); */
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frm {
  width: 30%;
  background-color: #fff;
  box-shadow: 10px;
  display: none;
}

.frm-head {
  background-color: gainsboro;
  display: flex;
  gap: 5px;
  padding: 5px;
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 40px;
}

.frm-head span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  padding: 5px;
  border-radius: 20px;
}

.frm-head p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.frm-head img {
  max-width: 50px;
  height: auto;
  border-style: none;
}

.footer {
  text-align: center;
}

.footer p {
  font-style: italic;
  font-size: 15px;
}

.footer span {
  font-size: 10px;
}

.frm-input form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-left: 40px;
  margin-bottom: 30px;
}

.frm-input form button {
  background-color: #08769b;
  width: 310px;
  height: 50px;
  border-radius: 7px;
  padding: 15px;
  color: #fff;
  border: none;
  margin-top: 10px;
  cursor: pointer;
}

.frm-input form input {
  border-radius: 7px;
  border: 1px solid #08769b;
  transition: 0.3s ease-in-out;
  width: 310px;
  height: 50px;
  padding: 15px;
}
.frm-input form input:hover {
  border: 1px solid #08769b;
}
@media (max-width: 600px) {
  .frm {
    width: 80%;
  }

  .frm-input form {
    margin-left: 20px;
    gap: 5px;
  }

  .frm-input form button,
  .frm-input form input {
    width: 90%;
    height: 40px;
  }

  .frm-head {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .frm-head img {
    max-width: 30px;
  }

  .frm-head span {
    font-size: 0.9em;
    padding: 3px;
    border-radius: 15px;
  }

  .footer p {
    font-size: 12px;
  }

  .footer span {
    font-size: 8px;
  }
}

@media (max-width: 1030px) {
  .frm {
    width: 80%;
  }

  .frm-input form {
    margin-left: 20px;
    gap: 5px;
  }

  .frm-input form button,
  .frm-input form input {
    width: 90%;
    height: 40px;
  }

  .frm-head {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .frm-head img {
    max-width: 30px;
  }

  .frm-head span {
    font-size: 0.9em;
    padding: 3px;
    border-radius: 15px;
  }

  .footer p {
    font-size: 12px;
  }

  .footer span {
    font-size: 8px;
  }
}

/*  */
.admin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.admin-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  margin-bottom: 20px;
}

.table-grid {
  display: grid;
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.table-header,
.table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid #ddd;
}

.table-header {
  background-color: #009879;
  color: #ffffff;
}

.table-cell {
  padding: 12px 15px;
  border-right: 1px solid #ddd;
  text-align: left;
}

.table-cell:last-child {
  border-right: none;
}

.table-body .table-row:nth-child(even) {
  /* background-color: #f3f3f3; */
}

/* .table-body .table-row:last-child {
  border-bottom: 2px solid #009879;
} */

@media (max-width: 600px) {
  .table-header {
    display: none;
  }

  .table-row {
    display: block;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .table-cell {
    display: flex;
    /* justify-content: space-between; */
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    position: relative;
    font-size: 15px;
  }

  .table-cell:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    display: inline-block;
    width: 120px; /* Adjust width as needed */
    border-right: 1px solid #ddd; /* Vertical border between property and value */
    padding-right: 10px; /* Add space between border and text */
  }

  .table-cell:last-child {
    border-bottom: none;
  }
}
